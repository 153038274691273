import React from 'react'

// Images
import Quote from 'img/quote.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface CtaQuoteProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
}

const Section = styled.section``

const CtaWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  height: min-content;

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }

  @media (max-width: 991px) {
    &:before {
      content: '';
      position: absolute;
      right: 100%;
      top: 0px;
      bottom: 0px;
      width: 2000px;
      background-color: ${({ theme }) => theme.color.primary};
    }
  }
`

const Image = styled(Plaatjie)`
  left: -95px;
  bottom: 0;

  @media (min-width: 1400px) {
    width: 710px;
    height: 410px;
  }

  @media (max-width: 1439px) {
    width: 490px;
    height: 370px;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    width: 330px;
    height: 230px;
    z-index: 2;
  }

  @media (max-width: 991px) {
    width: 330px;
    height: 230px;
    right: -75px;
    left: unset;
    z-index: 2;
  }

  @media (max-width: 767px) {
    width: 280px;
    height: 190px;
    right: -80px;
    left: unset;
  }

  @media (max-width: 575px) {
    right: -10px;
    width: 100%;
    height: 250px;
  }
`

const Content = styled(ParseContent)`
  padding: 3rem 0;

  @media (min-width: 1200px) {
    padding-left: 2rem;

    & p {
      font-size: 30px;
      line-height: 52px;
      font-weight: 100;
    }
  }

  @media (max-width: 1199px) {
    padding-left: 2rem;
    & p {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.mega};
      font-weight: 100;
    }
  }

  @media (max-width: 991px) {
    padding: 4rem 16rem 4rem 2rem;

    & p {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.mega};
      font-weight: 100;
    }
  }

  @media (max-width: 767px) {
    padding: 4rem 14rem 4rem 2rem;

    & p {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.mega};
      font-weight: 100;
    }
  }

  @media (max-width: 575px) {
    margin-bottom: 13rem;
    padding: 2.5rem 0 2.5rem 2rem;

    & p {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.mega};
      font-weight: 100;
    }
  }
`

const SvgWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: -40px;

  @media (max-width: 991px) {
    top: 20px;
    left: -10px;
  }

  @media (max-width: 575px) {
    top: 15px;
    left: 0;
  }
`

const CtaQuote: React.FC<CtaQuoteProps> = ({ fields }) => (
  <Section className="mb-lg-5 py-lg-5">
    <div className="container">
      <CtaWrapper className="position-relative d-flex justify-content-xl-end align-items-center mt-lg-5">
        <Image image={fields.image} alt="" className="position-absolute" />
        <div className="row justify-content-end">
          <div className="col-lg-6 position-relative">
            <SvgWrapper>
              <Quote />
            </SvgWrapper>
            <Content content={fields.description} />
          </div>
          <div className="col-lg-1" />
        </div>
      </CtaWrapper>
    </div>
  </Section>
)

export default CtaQuote
