import React from 'react'

// Third party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { CtaProps } from 'components/flex/Cta/CtaShell'

const Section = styled.section`
  height: 500px;
`

const BackgroundWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
`

const Image = styled(Plaatjie)`
  height: 500px;
  max-width: 1440px;
`

const Content = styled(ParseContent)`
  background-color: ${({ theme }) => theme.color.secondary};

  & span {
    color: ${({ theme }) => theme.color.light};
  }
`

const CtaHero: React.FC<CtaProps> = ({ fields }) => (
  <Section className="position-relative">
    <BackgroundWrapper className="position-absolute d-flex justify-content-center">
      <Image image={fields.image} alt="" className="w-100" />
    </BackgroundWrapper>
    <div className="container position-relative h-100">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-xl-10 h-100">
          <div className="row justify-content-end align-items-center h-100">
            <div className="col-lg-6 me-lg-5">
              <Content content={fields.description} className="p-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default CtaHero
