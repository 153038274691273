import React from 'react'

// Third party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { CtaProps } from 'components/flex/Cta/CtaShell'

const CtaWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  height: 289px;

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const Image = styled(Plaatjie)`
  bottom: 0;

  @media (min-width: 1440px) {
    left: -35px;
    width: 530px;
    height: 390px;
  }

  @media (max-width: 1439px) {
    width: 530px;
    height: 390px;
    left: -70px;
  }

  @media (max-width: 1199px) {
    width: 430px;
    height: 250px;
  }

  @media (max-width: 991px) {
    width: 430px;
    height: 290px;
  }

  @media (max-width: 575px) {
    width: 310px;
    height: 195px;
  }
`

const Content = styled(ParseContent)`
  & p {
    padding-top: 1rem;
  }

  & h1,
  & h2,
  & h3 {
    margin-bottom: 0;
  }

  @media (min-width: 576px) {
    & p {
      padding-right: 3rem;
    }
  }

  @media (max-width: 350px) {
    & h1,
    & h2,
    & h3 {
      & span {
        font-size: 50px;
      }
    }
  }
`

const ImageDescription = styled(ParseContent)`
  & p {
    font-size: ${({ theme }) => theme.font.size.mega};
    font-family: ${({ theme }) => theme.font.family.secondary};
    line-height: ${({ theme }) => theme.font.size.mega};
  }
`

const Cta: React.FC<CtaProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 py-3">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            <div className="col-lg-5 mb-lg-0 mb-3">
              <Content content={fields.description} />
            </div>
            <div className="col-lg-7">
              <CtaWrapper className="position-relative d-flex justify-content-end align-items-xl-center mt-lg-5">
                <Image
                  image={fields.image}
                  alt=""
                  className="position-absolute"
                />
                <ImageDescription
                  content={fields.image?.description}
                  className="pt-4 pt-xl-0"
                />
              </CtaWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Cta
